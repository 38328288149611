import Link from "next/link";
import Layout from "../layouts/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosSearch } from "react-icons/io";
import Data from '../../src/layouts/schema.json'
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useState } from "react";
import Preloader from "../components/Preloader";

const NotFound = () => {
  const router = useRouter();
  const [mounted, setMounted] = useState(false);
  const currentPath = router.asPath;
  const extractedValue = currentPath.substring(1);
  useEffect(() => {
    let isValueMatched = false;
    Data.Console.map((item) => {
      if (item.page == extractedValue) {
        router.push(item.url);
        isValueMatched = true;
      }
    });

    if (!isValueMatched) {
      setMounted(true);
    }
  }, [router])

  if (!mounted)
    return <Preloader />

  return (
    <Layout title={"Block in ROBOTS.TXT"}>
      <div className="not-found-content space-pt--r100 space-pb--r100">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={6} md={10}>
              <div className="text-center">
                {/* <div className="error-txt">404</div> */}
                <img src="/assets/404.webp" width={250} alt="404"/>
                <h5 className="mb-2 mb-sm-3">
                  Oops! You have landed on an under development page.
                </h5>
                {/* <p>
                  The page you are looking for was moved, removed, renamed or
                  might never existed.
                </p> */}
                <p>We are working to get this page up and running soon!</p>
                {/* <div className="search-form pb-3 pb-md-4">
                  <form method="post">
                    <input
                      name="text"
                      id="text"
                      type="text"
                      placeholder="Search"
                      className="form-control"
                    />
                    <button type="submit" className="btn icon-search">
                      <IoIosSearch />
                    </button>
                  </form>
                </div> */}
                <Link href="/">
                  <a className="btn btn-fill-out">Back To Home</a>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default NotFound;
